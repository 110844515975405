import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import "../App.css";

function Footer() {
  return (
    <div className="Footer">
      <h1 className="footerinsta"> follow us.</h1>
      <a href="https://instagram.com/thatsitnothing" className="footerinsta">
        <FontAwesomeIcon icon={faInstagram} size="1x" />
      </a>
      <a
        href="https://www.youtube.com/channel/UC7w8J5L_oCVKOB1Qjw959zA/"
        className="footerinsta"
      >
        <FontAwesomeIcon icon={faYoutube} size="1x" />
      </a>
      <a href="https://twitter.com/apparelartifice" className="footerinsta">
        <FontAwesomeIcon icon={faTwitter} size="1x" />
      </a>
    </div>
  );
}

export default Footer;
