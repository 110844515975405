import React from "react";
import { Jumbotron as Jumbo, Container, Button } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "./bkgimage.css";

export const Announcement = () => (
  <div className="Announcement">
    <h1 className="drop">just sold out. check for next release date.</h1>
  </div>
);
