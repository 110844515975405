import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";
import Line from "./Line.jpg";

const Styles = styled.div`


.navbar-brand, .navbar-nav .nav-link {
  color: black;
  font-family: "Raleway";
  
  &:hover{
    color: grey;
  }
  .navbar-brand, .navbar-nav .nav-toggle {
  outline: none;
  }
  
  
 

`;

export const NavigationBar = () => (
  <Styles>
    <Navbar expand="lg" fixed="top">
      <Navbar.Brand href="/">artifice apparel</Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        class="navbar-toggle"
      ></Navbar.Toggle>

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item>
            <Nav.Link href="https://artificeapparel.com/about/">about</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="https://artificeapparel.com/shop/">shop</Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Styles>
);
