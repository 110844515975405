import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./scroll.css";
import "./scroll.scss";
import ScrollOut from "scroll-out";
import glitch from "./stickerimgs/glitchnoback.png";
import mtn from "./stickerimgs/mtnmaybe.png";
import camo from "./stickerimgs/camonoback.png";
import drop from "./stickerimgs/dropnoback.png";

export default class ScrollerLeft extends Component {
  componentDidMount() {
    window.__be = window.__be || {};
    window.__be.id = "5b3a47b4b30a36000769d821";
    (function() {
      var be = document.createElement("script");
      be.type = "text/javascript";
      be.async = true;
      be.src =
        ("https:" == document.location.protocol ? "https://" : "http://") +
        "unpkg.com/scroll-out/dist/scroll-out.min.js";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(be, s);
    })();
    window["ScrollOut"]();
  }

  render() {
    return (
      <body>
        <section className="camoslide">
          <div id="glitch">
            <h1 data-scroll>glitch.</h1>
            <p data-scroll>limited edition.</p>
          </div>
          <img src={glitch} className="glitchsticker" />
        </section>
        <section>
          <div id="camo">
            <h1 data-scroll>camo.</h1>
            <p data-scroll>our very first sticker.</p>
          </div>
          <img src={camo} className="glitchsticker" />
        </section>
        <section>
          <div id="mtn">
            <h1 data-scroll>alpine.</h1>
            <p data-scroll>time to go ski.</p>
          </div>
          <img src={mtn} className="glitchsticker" />
        </section>
        <section>
          <div id="drop">
            <h1 data-scroll>drop.</h1>
            <p data-scroll>save the earth.</p>
          </div>
          <img src={drop} className="glitchsticker" />
        </section>
      </body>
    );
  }
}
