import React from "react";
import { Jumbotron } from "./components/Jumbotron.js";
import { Layout } from "./components/Layout";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Jumbotron as Jumbo, Container, Button } from "react-bootstrap";
import "./components/bkgimage.css";
import { JumboAbout } from "./components/JumboAbout.js";
import { JumboShop } from "./components/JumboShop.js";
import { Announcement } from "./components/Announcement";
import { NavigationBar } from "./components/NavigationBar";
import buff from "./components/pagebuffer";
import ScrollerLeft from "./components/scroll.js";

import Card from "./components/CardUI";
import ProductCard from "./components/ProductCardLarge";
import HoverCard from "./components/hovercard/hovercard";

export const Home = () => (
  <div>
    <div className="buff" />
    <div className="navback">
      <NavigationBar />
    </div>
    <Announcement />
    <Jumbotron>
      <h1 className="maintext" id="main">
        that's it. nothing. collection
      </h1>
    </Jumbotron>
    <Layout>
      <p className="words">
        that's it. nothing. stickers currently in production, ordering will be
        available soon.
      </p>
      <p id="about"></p>
    </Layout>
    <ScrollerLeft />

    <Layout>
      <p></p>
    </Layout>

    <Layout>
      <p className="shopwords" id="shop">
        {" "}
        only ships within USA.
      </p>
    </Layout>
  </div>
);
