import React, { Component, setCustomText } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter
} from "react-router-dom";
import { Home } from "./Home";
import { Layout } from "./components/Layout";

import { Jumbotron } from "./components/Jumbotron.js";
import Footer from "./components/Footer.js";

const customTextProps = {
  style: {
    fontFamily: "Raleway"
  }
};

class App extends Component {
  componentDidMount() {
    // Call our fetch function below once the component mounts
    this.callBackendAPI()
      .then(res => this.setState({ data: res.express }))
      .catch(err => console.log(err));
  }
  // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
  callBackendAPI = async () => {
    const response = await fetch("/express_backend");
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message);
    }
    return body;
  };

  render() {
    return (
      <HashRouter basename="/">
        <React.Fragment>
          <Router>
            <Switch>
              <Route
                exact
                path={"process.env.PUBLIC_URL + " / ""}
                component={Home}
              />
            </Switch>
          </Router>
          <Footer />
        </React.Fragment>
      </HashRouter>
    );
  }
}

export default App;
