import React from "react";
import { Jumbotron as Jumbo, Container, Button } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "./bkgimage.css";
import "./slider.css";
import img1 from "./Sliderimgs/1.png";
import imgs2 from "./Sliderimgs/2.png";
import imgs3 from "./Sliderimgs/3.png";
import imgs4 from "./Sliderimgs/4.png";

export const Jumbotron = () => (
  <Jumbo className="myjumbo" fluid>
    <div className="maintext">
      <h1 className="title">that's it. nothing. collection</h1>
      <div className="btnbox">
        <Button className="btnshop" href="https://artificeapparel.com/shop/">
          shop now.
        </Button>{" "}
      </div>
    </div>
  </Jumbo>
);
